<template>
    <div v-if="$root.applicationLoaded && $application && (!$route.meta.public && $user)">
        <nav class="navbar navbar-light bg-light">
            <div class="container-fluid">
                <router-link class="navbar-brand" to="/">
                <img :src="Logo" width="30" height="30" class="d-inline-block align-top" alt="Logo" loading="lazy">
                Govern
                </router-link>
                <div class="d-flex">
                    <button v-if="$user" class="btn btn-dark" @click="$root.logout()">Logout of {{ $user.name }}</button>
                </div>
            </div>
        </nav>
        <transition name="fade" mode="out-in">
            <div class="container-fluid">
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
import Logo from '@/assets/logo.png';

export default {
    name: 'default',

    data() {
        return {
            Logo,
        }
    },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>