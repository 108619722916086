<template>
  <div v-if="$root.applicationLoaded && $application">
    <slot />
  </div>
</template>

<script>
export default {
    name: 'blank',
}
</script>