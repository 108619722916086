<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import Default from './layouts/Default';
import Blank from './layouts/Blank';

export default {
  name: 'app',

  components: {
    Default,
    Blank,
  },

  data() {
    return {
      defaultLayout: 'default',
    };
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || this.defaultLayout);
    },
  },

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>