import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import axios from 'axios';
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import VueToastify from "vue-toastify";
Vue.use(VueToastify);

axios.defaults.withCredentials = true;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),

  data() {
    return {
      applicationLoaded: false,
      application: null,
      user: false,
    }
  },

  methods: {
    async getApplication() {
      await axios.get('/api/v1/application/get')
        .then(({ data }) => {
          this.application = data;
          this.user = data.user;
          this.user.hasPermission = this.userHasRole,
          delete this.application.user;
          this.applicationLoaded = true;
          Vue.prototype.$application = this.application;
          Vue.prototype.$user = this.user;

          if (this.user) {
            if (this.user.restricted && this.$route.name !== 'suspended') {
              this.$router.push('/suspended/');
            }

            if (!this.user.verified && this.$route.name !== 'verify-email') {
              this.$router.push('/verify-email/');
            }
          } else {
            if (!this.$route.meta.public && !this.user) {
              console.log('ayy');
              this.$router.push('/login/');
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      },

      userHasRole(permission) {
        return !!this.user.permissions.includes(permission);
      },

      logout() {
        this.axios.post('/api/v1/auth/logout')
          .then(() => {
            location.reload();
          });
      },
  },

  mounted() {
    this.getApplication();
  },
}).$mount('#app')
