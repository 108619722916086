<template>
  <div>
    <h1 class="mt-3">Tools</h1>
      <div class="row">
        <tool-square
          v-for="(tool, index) in tools"
          :key="index"
          :name="tool.title"
          :image="tool.image"
          :link="tool.url"
          :icon="tool.icon"
          :disabled="!$user.hasPermission(tool.permission)"
        ></tool-square>
      </div>
  </div>
</template>

<script>
import ToolSquare from '@/components/ToolSquare';

export default {
  name: 'Home',

  components: {
    ToolSquare,
  },

  data() {
    return {
      tools: [],
    }
  },

  methods: {
    getTools() {
      this.axios.get('/api/v1/tools/get')
        .then(({ data }) => {
          this.tools = data;
        });
    },
  },

  mounted() {
    this.getTools();
  },
}
</script>
