<template>
    <div class="cursor-pointer col-lg-2 col-md-3 col-6">
        <component :is="disabled ? 'span' : 'router-link'" :to="`${link}/`">
            <div class="box" :class="{ 'disabled-colour': disabled, 'enabled-colour': !disabled }">
                <img :class="{ 'disabled-opacity': disabled, 'enabled-opacity': !disabled  }" :src="image" />
                <div class="absolute-center">
                    <h1 class="text-white text-center large"><i :class="icon"></i></h1>
                    <h1 class="text-white text-center" style="font-size: 100%">{{ name }}</h1>
                </div>            
            </div>
        </component>
    </div>
</template>

<script>
export default {
    name: 'tool-square',

    props: {
        name: {
            type: [String, Number],
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped>
.disabled-opacity {
    opacity: 0.2;
}

.disabled-colour {
    background-color: #999999 ;
}

.enabled-opacity {
    opacity: 0.5;
}

.enabled-colour {
    background-color: #000000 ;
}

.box {
    border-radius: 4px;
    padding-bottom: 100%;
    background-size: cover;
    transform: scale(1);
    overflow: hidden;
}

img {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.box img {
    max-width: 100%;
    transition: all 2.0s;
    display: block;
    width: 100%;
    transform: scale(1);
}


.box:hover img {
    transform: scale(1.3);
}

.col-lg-2 {
    margin-top: 30px !important;
}

.absolute-center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) 
}

.large {
    font-size: 5em;
}

@media only screen and (max-width: 377px) {
    .large {
        font-size: 3em;
    }
}

@media only screen and (max-width: 1209px) {
    .large {
        font-size: 2em;
    }
}
</style>