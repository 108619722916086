import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/infrastructure/',
    name: 'infrastructure',
    component: () => import(/* webpackChunkName: "Infrastructure" */ '@/views/ToolPage'),
    meta: {
      heading: 'Infrastructure'
    }
  },
  {
    path: '/legal/',
    name: 'legal',
    component: () => import(/* webpackChunkName: "Legal" */ '@/views/ToolPage'),
    meta: {
      heading: 'Legal'
    }
  },
  {
    path: '/infrastructure/dashboard/:test',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard'),
  },
  {
    path: '/login/',
    name: 'login',
    component: () => import(/* webpackChunkName: "Signon" */ '@/components/signon/Signon'),
    meta: {
      public: true,
      layout: 'Blank',
    }
  },
  {
    path: '/register/',
    name: 'register',
    component: () => import(/* webpackChunkName: "Signon" */ '@/components/signon/Signon'),
    meta: {
      public: true,
      layout: 'Blank',
    }
  },
  {
    path: '/verify-email/',
    name: 'verify-email',
    component: () => import(/* webpackChunkName: "Signon" */ '@/components/signon/Signon'),
    meta: {
      public: true,
      layout: 'Blank',
    }
  },
  {
    path: '/forgot-password/',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "Signon" */ '@/components/signon/Signon'),
    meta: {
      public: true,
      layout: 'Blank',
    }
  },
  {
    path: '/password/reset/:token',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "Signon" */ '@/components/signon/Signon'),
    meta: {
      public: true,
      layout: 'Blank',
    }
  },
  {
    path: '/suspended',
    name: 'suspended',
    component: () => import(/* webpackChunkName: "Signon" */ '@/components/signon/Signon'),
    meta: {
      layout: 'Blank',
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
